import React from 'react';

import Layout from '../components/utils/layout';
import SEO from '../components/utils/seo';
import Button from '../components/atoms/button';

const NotFoundPage = () => (
  <Layout>
    <SEO title='404: Not found' image='logo' />
    <h1>Nie znaleziono</h1>
    <p>
      Musiałeś coś źle wpisać.. 🤔 Spróbuj jeszcze raz lub wróć do strony
      głównej.
    </p>
    <Button>Do strony głównej</Button>
  </Layout>
);

export default NotFoundPage;
